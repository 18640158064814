import { LazyNewbieTour } from '#components'
import { useActSignInStore } from '@/stores/activity'

// 显示新人引导弹窗
const showNewcomerGuide = () => {
  const { emitEventPopup } = useFacePunch()
  const newcomerGuidance = getLocalDESJson(StorageKey.NEWCOMER_GUIDANCE) as Inewcomer_guidance
  return new Promise((resovle) => {
    if (newcomerGuidance && newcomerGuidance.mainSwitch && newcomerGuidance.mobileGuidance) {
      // 消费掉新人引导弹窗
      removeLocal(StorageKey.NEWCOMER_GUIDANCE)
      useTimeoutFn(
        () =>
          openDialog(LazyNewbieTour, {
            recommendenGameList: newcomerGuidance.gameRecommendation ? newcomerGuidance.games : [],
            onClose: async () => {
              resovle(true)
              emitEventPopup(FrontEndEventType_const.FrontEndEventTypeUserCloseGuidance)
            },
          }),
        1000
      )
    } else {
      resovle(true)
    }
  })
}

export const useUserData = () => {
  const { $fb } = useNuxtApp()
  const globalStore = useGlobalStore()
  const { basicConfirParams } = storeToRefs(globalStore)
  const { userInfo, isLogin, userCurrencyId } = storeToRefs(useUserStore())
  const { guestLogin, refreshUserToken } = useUserStore()

  const initUserToken = async () => {
    try {
      if (!!getLocalBase64(StorageKey.TOKEN)) {
        await refreshUserToken()
      } else {
        await guestLogin()
      }
    } catch (e: any) {
      Log.error('refresh token failed', e)
      try {
        await guestLogin()
        // throw {
        //   code: -1,
        //   message:
        //     'rpc error: code = Unavailable desc = last connection error: connection error: desc = "transport: Error while dialing dial tcp 10.42.87.44:45407: connect: connection refused"; last resolver error: produced zero addresses',
        //   stack:
        //     'rpc error: code = Unavailable desc = last connection error: connection error: desc = "transport: Error while dialing dial tcp 10.42.87.44:45407: connect: connection refused"; last resolver error: produced zero addresses\napp => /data/jenkins/workspace/LIVE_STREAM_V2_STAGE/live-service/service/api/app/user/user.go:512  [-1]rpc error: code = Unavailable desc = last connection error: connection error: desc = "transport: Error while dialing dial tcp 10.42.87.44:45407: connect: connection refused"; last resolver error: produced zero addresses',
        //   data: null,
        // }
      } catch (e) {
        Log.debug('guest login failed.', e)
        if (e.code === 401) {
          nextTick(() => openModalRoute({ modal: 'ath', tab: 'register', replace: true }))
        } else {
          throw e
        }
      }
    }
  }

  // 进站流程
  function siteEntryFlow() {}

  function loadUserData() {
    const inviteCode = globalStore.getInviteCode()
    if (!isLogin.value && inviteCode) {
      // 消费邀请码
      consumPageMsg('invite_link_visit', (payload: any) =>
        request(
          verticalProxyInviteEvent({
            eventType: invite_event_type_const.invite_link_visit,
            // inviteCode: inviteCode,
          })
        ).catch(Log.error)
      )
    }

    // 邀请裂变配置
    useInviteStore().initInviteIncomeInfo().catch(Log.error)
    // 字典数据
    useProviderStore().initProviders().catch(Log.error)
    useCasinoGameTags().initGameTags().catch(Log.error)

    // 设置FB上报数据
    request(basicPromotionConfigInfoExtra({}))
      .then((response) => $fb.setUserData(_buildFBUserData(userInfo.value, response)))
      .catch(Log.error)
    // 未开启游客模式跳转登录
    const hasGuestLogin =
      basicConfirParams.value?.loginType?.findIndex((item) => item.loginType === login_type_const.LoginGuest) > -1
    if (process.client && !isLogin.value && !hasGuestLogin) {
      nextTick(() => openModalRoute({ modal: 'ath', tab: 'register', replace: true }))
      return
    }

    // 货币相关活动
    useNewcomerStore().getActivityNewcomerRechargeConf(String(userCurrencyId.value), true).catch(Log.error)
    useActSignInStore().initActSignIn().catch(Log.error)
    useActFPCollStore().initActFPColl().catch(Log.error)
    useActWMSubsStore().initWMSubsActInfo().catch(Log.error)
    useActActivityCenterStore().initActActivityCenter().catch(Log.error)

    const router = useRouter()
    const localePath = useLocalePath()
    const path = router.currentRoute.value.path

    // 进站流程
    if (localePath('/') === path || localePath('/') + '/' === path) {
      const { $device } = useNuxtApp()
      const { emitEnterSitePopup, emitEventPopup, unlockFacePopup } = useFacePunch()

      const isRegister = getLocal(StorageKey.IS_REGISTERED)
      removeLocal(StorageKey.IS_REGISTERED)

      nextTick(async () => {
        // $device.isMobile && openDialog(LazyNewbieTour, { recommendenGameList: [] }) // TESTCASE
        // 新人引导弹窗
        if ($device.isMobile && isLogin.value && isRegister) {
          await showNewcomerGuide()
        }

        unlockFacePopup()

        // 注册后事件打脸图
        if (isRegister) {
          await emitEventPopup(FrontEndEventType_const.FrontEndEventTypeBackToHallAfterRegister)
        }

        // 进入大厅打脸图 05.popup.global.ts 中会去执行
        // await emitEventPopup(FrontEndEventType_const.FrontEndEventTypeUserEnterHall)

        // 进站打脸图
        emitEnterSitePopup()
      })

      // 绑定手机/邮箱弹窗
      // const { bindPhoneNotice, bindEmailNotice } = basicConfirParams.value || {}
      // const { phone, email, userId } = userInfo.value || {}
      // const isReachPhoneNoticeTime =
      //   Date.now() - Number(getLocal(StorageKey.LAST_OPENED_BINDPHONE + '_' + userId) || 0) > 72 * 60 * 60 * 1000
      // const isReachEmailNoticeTime =
      //   Date.now() - Number(getLocal(StorageKey.LAST_OPENED_BINDEMAIL + '_' + userId) || 0) > 72 * 60 * 60 * 1000

      // if (isLogin.value && bindPhoneNotice === '1' && !phone && isReachPhoneNoticeTime) {
      //   nextTick(() => openDialog(LazyVerifyPhone, { onBeforeClose: emitEnterSitePopup }))
      //   setLocal(StorageKey.LAST_OPENED_BINDPHONE + '_' + userId, Date.now())
      // } else if (isLogin.value && bindEmailNotice === '1' && !email && isReachEmailNoticeTime) {
      //   nextTick(() => openDialog(LazyVerifyEmail, { onBeforeClose: emitEnterSitePopup }))
      //   setLocal(StorageKey.LAST_OPENED_BINDEMAIL + '_' + userId, Date.now())
      // } else {
      //   // 打脸图弹窗
      //   nextTick(emitEnterSitePopup)
      // }
    }
  }

  function _buildFBUserData(userInfo: Iuser_basic_info = {}, userLoc: IGetPromotionConfigExtraResp) {
    return {
      email: userInfo.email,
      phone: userInfo.phone,
      gender: [undefined, 'm', 'f'][userInfo?.gender || 0] as any,
      first_name: userInfo?.nickName,
      date_of_birth: userInfo?.birthday && formatDate(userInfo?.birthday),
      city: userLoc?.ct,
      state: userLoc?.st,
      zip: userLoc?.zp,
      country: userLoc?.country,
    }
  }
  return { initUserToken, loadUserData }
}
