import { LazyActDailySignin, LazyActFacePunch, LazyActFPCollection } from '#components'

// 普通打脸图，新人充值打脸图
const picturePopupType: Array<any> = [
  activity_popup_type_const.activity_popup_general,
  activity_popup_type_const.activity_popup_newcomer_recharge,
]

// 点击事件
const clickPopupEvents: Array<any> = [
  PopupEventType_const.EventTypeRegisterGuidance, // 引导注册
  PopupEventType_const.EventTypeTTUserDownloadGuidance, // TT用户引导下载
]

// 进站触发打脸图
const enterSiteList = ref<Iactivity_popup[]>([])
// 点击触发打脸图
const clickPopupList = ref<Iactivity_popup[]>([])
// 所有打脸图
const allPopupList = ref<Iactivity_popup[]>([])

// 打脸图任务列表
const pupupTaskList = ref<Array<() => Promise<void>>>([])
const isRunTask = ref(false)

export const useFacePunch = () => {
  const fetchFacePunchs = async () => {
    const enterSitePopup = await request(
      activityPopupList({ triggerType: PopupTriggerType_const.PopupTriggerTypeEnterSite })
    )
    const clickEventPopup = await request(activityPopupListMerge({}))
    const allPopup = await request(activityPopupListAll({}))

    enterSiteList.value = enterSitePopup?.popupList || []
    clickPopupList.value = clickEventPopup?.popupList || []
    allPopupList.value = allPopup?.popupList || []

    // Log.debug(enterSiteList.value, "enterSiteList=======")
    // Log.debug(clickPopupList.value, "clickPopupList=======")
    // Log.debug(allPopupList.value, "allPopupList=======")
  }

  const checkClickPopup = () => {
    let hit = false
    if (clickPopupList.value.length) {
      for (const item of clickPopupList.value) {
        if (clickPopupEvents.includes(item.eventType)) {
          hit = true
          openDialog(LazyActFacePunch, { data: item })
          break
        }
      }
    }
    return hit
  }

  const emitEnterSitePopup = async () => {
    return new Promise(async (resolve) => {
      _addTask(async () => {
        // 获取打脸图
        await fetchFacePunchs()

        // 预加载图片
        for (const item of enterSiteList.value) {
          if (picturePopupType.includes(item?.popupType) && item.image) {
            new Image().src = item.image
          }
        }

        try {
          let isClickOpen = false
          while (enterSiteList.value.length > 0) {
            if (isClickOpen) break
            const item = enterSiteList.value.shift()
            if (
              item?.popupType === activity_popup_type_const.activity_popup_general ||
              item?.popupType === activity_popup_type_const.activity_popup_limitFirstRecharge ||
              item?.popupType === activity_popup_type_const.activity_popup_deductTicket ||
              item?.popupType === activity_popup_type_const.activity_popup_newcomer_recharge
            ) {
              await new Promise((_resolve) =>
                openDialog(LazyActFacePunch, {
                  data: item,
                  onBeforeClose: () => _resolve(true),
                  onBeforeOpen: () => {
                    _resolve(true)
                    isClickOpen = true
                  },
                })
              )
            } else if (item?.popupType === activity_popup_type_const.activity_popup_signIn) {
              await new Promise((_resolve) => openDialog(LazyActDailySignin, { onBeforeClose: _resolve }))
            } else if (item?.popupType === activity_popup_type_const.activity_popup_hinge) {
              await new Promise((_resolve) => openDialog(LazyActFPCollection, { onBeforeClose: _resolve }))
            } else {
              Log.warn('undefined type', item?.popupType)
              resolve(false)
              return
            }
          }
          resolve(true)
        } finally {
          // 发送打脸图触发完毕事件
          emitter.emit('act-face-punch-end')
        }
      })
    })
  }

  /**
   * 触发事件打脸图
   * @param event 事件
   * @param isAppendTask 是否添加到任务队列，默认添加，在某些情况不添加防止阻塞，不添加到任务队列可能会出现多个打脸图同时出现问题
   * @returns
   */
  const emitEventPopup = (event: ValueOf<typeof FrontEndEventType_const>, isAppendTask = true) => {
    // Log.info('emit event popup', event)
    return new Promise(async (resolve) => {
      const fn = async () => {
        // Log.info('execute event popup', event)
        const eventPopup = await request(
          activityPopupList({
            triggerType: PopupTriggerType_const.PopupTriggerTypeEvent,
            eventType: event,
          })
        )

        if (!eventPopup?.popupList?.length) {
          resolve(false)
          return
        }

        let isClickOpen = false

        for (const item of eventPopup.popupList) {
          if (isClickOpen) break
          await new Promise((_resolve) =>
            openDialog(LazyActFacePunch, {
              data: item,
              onBeforeOpen: () => {
                // 如果点击了打脸图，则后续的打脸图不再弹出
                isClickOpen = true
                _resolve(true)
              },
              onBeforeClose: () => _resolve(true),
            })
          )
        }

        resolve(true)
      }
      if (isAppendTask) {
        _addTask(fn)
      } else {
        fn()
      }
    })
  }

  const openPopup = async (popupId: number, isRefresh = true) => {
    const popup = allPopupList.value.find((item) => item.popupId === popupId)
    if (!popup && isRefresh) {
      await fetchFacePunchs()
      return openPopup(popupId, false)
    }
    if (popup) {
      openDialog(LazyActFacePunch, { data: popup })
    }
  }

  // 新人引导弹窗优先级最高，在调用unlockFacePopup方法之前，不弹出打脸图，用isRunTask控制不弹出打脸图
  const unlockFacePopup = () => {
    Log.debug('===========解锁popup')
    isRunTask.value = false
    _startTask()
  }

  const lockFacePopup = () => {
    Log.debug('===========锁定popup')
    isRunTask.value = true
  }

  const _addTask = (task: () => Promise<void>) => {
    pupupTaskList.value.push(task)
    _startTask()
  }

  const _startTask = async () => {
    if (isRunTask.value) return

    isRunTask.value = true

    while (pupupTaskList.value.length) {
      const task = pupupTaskList.value.shift()
      const t = task()
      Log.debug(t, 'task start')
      await t
      Log.debug(t, 'task end')
      // await pupupTaskList.value.shift()()
    }

    isRunTask.value = false
  }

  return { emitEnterSitePopup, checkClickPopup, emitEventPopup, openPopup, lockFacePopup, unlockFacePopup }
}

export const facePunchEvent = ({
  cb,
  auth,
  evt,
}: {
  auth?: boolean
  cb?: () => void
  evt: TouchEvent | MouseEvent
}) => {
  const { checkClickPopup } = useFacePunch()
  const { isLogin } = useUserStore()
  const showAuthModal = auth && !isLogin

  // 判断是否展示打脸图
  if (checkClickPopup()) {
    Log.debug('checkClickPopup')
    evt.stopPropagation()
    evt.preventDefault()
    return
  }

  // 判断是否展示登录弹窗
  if (showAuthModal) {
    evt.stopPropagation()
    evt.preventDefault()
    return openModalRoute({ modal: 'ath', tab: 'signin' })
  }

  cb && cb()
}
